.staff-cal{
    font-size: small;
    width:  -moz-fit-content;
    width: fit-content;
}
#staff-header-btn{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.staff-card{
    border-radius: 2rem;
    background-color: #ffffff;
    box-shadow: 1px 1px 8px #818181;
    transition: all .1s;
}

.staff-card.not_active{
    background-color: #e1dfdf;
}

.staff-hover-card{
    position: absolute;
    border-radius: 2rem;
    background-color: #ffffff;
    box-shadow: 1px 1px 8px #818181;
    transition: all .1s;
    overflow: hidden;
    width: 24.5%;
}

.staff-card-img img{
    width: 9.5rem;
    height: 9.5rem;
    border-radius: 1.5rem;
    object-fit: cover;
    background: #dfdfdf;
    
    /* border-radius: 1.5rem;
    aspect-ratio: 1 / 1; */
}

.staff-card-btn{
    border-radius: 1rem;
}
.staff-card-btnholder{
    position: absolute;
    bottom: 0;
    right: 0;

}
.staff-card-data{
    color: #3E66CD;
    font-weight: 500;
    font-size: 23px;
}
.staff-card-label{
    color: #8A8A8A;
    font-weight: 400;
    font-size: 12px;
}
.staff-card-name{
    color: #555555;
    font-weight: 600;
    font-size: 16px;
}
.staff-card-role{
    color: #555555;
    font-weight: 400;
    font-size: 12px;
}

.staff-modal{
    border-radius: 2rem;
}

.staff-img img{
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    object-fit: cover;
    background: #dfdfdf;
    /* overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #F2F2F2; */
}

.staff-btn-container{
    display: flex;
    align-items: flex-end;
    justify-content:flex-end;
}
.staff-btn{
    border-radius: 2rem;
}
