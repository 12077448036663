table.log-data tbody tr th{
    padding-right: 3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
table.log-data{
    font-size: 1rem;
}

.log-data{
    color: #000000;
}

.followup-card-head.serial-number.call{
    width: 4.16666667% !important;
    flex: 0 0 auto;
}

.followup-card-title.call{
    width: 4.16666667% !important;
    flex: 0 0 auto;
}

.btn.btn-success.call{
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}