.task-card{
    padding: 0px 6px 0px 6px;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    background-color: #ffff;
    border-radius: 9px;
    transition: all .1s;
    box-shadow: 0px 0px 3px #818181;
    transition: all .1s;
}
.task-top{
    position: relative;
    width: 100%;
    height: 5rem;
    background-color: #F3F3F3;
    margin: 6px 0px 0px 0px;
}
.staff-cal.add-task{
    position:absolute;
    right: 0;
}
.task-top.add{
    background-color: #414552;
}
.task-top.add:hover,.f-task:hover{
    background-color: #F3F3F3;
    color:black;
}
.task-top.add.active,.f-task.active{
    background-color: #F3F3F3;
}
.cardName{
    font-size: 12px;
    font-weight:600 ;
    letter-spacing: 0.004em;
}
.cardName2{
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #858585;
}
.task-btm{
    width: 100%;
    text-align: center;
}
.card-btm{
    gap: 40px;
    justify-content: center;
}
.card-imgc{
    cursor: pointer;
    position:absolute;
    top: 3px;
    right: 3px;
}
.card-img{
    padding: 3px 4px 4px 4px;
}
.crd-btm-num{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 15px;
}
.crd-btm-txt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #858585;
}
.card-btn{
    font-size: 12px;
    padding: 6px 70px 6px 70px;
    color:#3E66CD;
}
.card-btn:hover{
    background-color: #3E66CD;;
    color:white
}
.f-task{
    font-size: 11px;
    color:white;
    background-color: #414552;
}
.tForm> .txt,.textarea,.txt4,.agent-select{
    margin-top: 4px;
    padding: 10px;
    outline: none;
    border:none;
    width: 100%;
    font-size: 13px;
    background-color: #EBEBEB;
    resize: none;
    color: #4D4D4D;
}
.tform-text{
    font-size: 11.4px;
    font-weight: 600;
    letter-spacing: 0.03rem;
}
.tForm>.txt.text-2{
    width: 13rem;
    background-color: #979797;
}
.tForm>.txt.text-2::placeholder{
    color:white;
    opacity: 1;
}
.tForm{
    position: relative;
}

.tForm>.tForm-img{
    position: absolute;
    bottom: 0.7rem;
    right:0.7rem
}
.img-cal{
    border-top-right-radius: 0.9rem;
    border-bottom-right-radius: 0.9rem;
    padding:8px 15px 9px 15px;
    background-color: #102D77;
    position: absolute;
    bottom: 0.08rem;
    right:0rem;
}
@media screen and (width>768px){
    .img-cal{
        padding:7.99px 15px 9.55px 15px;
        margin-top: 2.05rem;
        bottom: 0.00rem;
        right:0rem
    }
}
.tForm>.txt3,.txt3{
    color: #4D4D4D;
    font-size: 10px;
    padding-bottom: 8px;
    width: 100%;
    height: 100%
}
.form-btn{
    border: 1px solid #05226A;
    color : #05226A; 
}
.form-btn:hover{
    color:white;
    background-color: #05226A;
}
.form-btn2{
    background-color: #05226A;
    color:white;
}
.form-btn2:hover{
    background-color: white;
    color: #05226A;
    border: 1px solid #05226A;
}
.tbar{
    background-color: #102D77;
}   
.tbar-add{
    padding-top: 1rem;
    right: 0px;
    height: 100%;
    position: absolute;
    background-color: #102D77;
}
.task-form-add{
    border-radius: 2rem;
    left: 0px;
    background-color: white;
}

.task-list-container{
    height: 28rem;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

}

.task-list-container::-webkit-scrollbar {
    display: none;
}

.task-list-container.agent{
    height: 30rem;
}
.tForm-cal{
    margin: 20px 0px 0px 25px;
    z-index: 2;
    position: absolute;
    width:18rem;
}
.tForm-cal1{
    width:100%;
}
.tForm1{
    width:auto;
    display: flex; 
}
.select .menu{
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}
.head-image{
    width: 2.5rem !important;
}
.select-header{
    height: 2.5rem;
}
.select-header>.txt1{
    font-size: 15px !important;
}
.select-header>.txt1>.txt2{
    font-size: 12px !important;
}
.search{
    width: 19rem !important;
    border:none;
    background-color: rgb(233, 233, 233) !important ;
}
.select .menu .item{
    margin-top: 0px !important;
    border-radius: 1rem !important;
}
.task-tab-main{
    position: relative;
    display: flex;
    /* align-items: start; */
    justify-content: space-between;
}
.task-tab{
    display: flex;
    width: auto;
    position: absolute;
    left: 0;
}


.agent-task-head{
    color: #FFF;
    font-size: 0.9375rem;
    font-weight: 590;
    letter-spacing: 0.03281rem;
}

.agent-task-body{
    color: #2C2C2C;
    font-size: 0.7875rem;
    font-weight: 400;
    line-height: 1.0625rem;
}

.agent-task-card{
    /* width: 75.4375rem; */
    height: 3.75rem;
    border-radius: 10px;
    background: #E5E5E5;
    box-shadow: 0px 1px 6px #818181;
    padding-top: .75rem !important;
    transition: all .1s;
}

.agent-task-card:hover{
    transform: scale(1.004);
}

.agent-task-description{
    background: #F8F8F8;
    border-radius: 8px;
    color: #2C2C2C;
    font-size: 0.625rem;
    line-height: 0.75rem;
    overflow: hidden;
}

.task-description-popover{
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 1rem;
    width: 16.9rem;
    overflow-y:hidden;
    overflow-x: hidden;
}

.task-desc-pop-container{
    width: 15.75rem;
    background: #F8F8F8;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    color: #2C2C2C;
    font-size: 0.7425rem;
    line-height: 1rem;
}



.task-status-dropdown{
    border-radius: 8px;
    padding: 0.1rem 0rem 0rem 0.75rem;
    font-weight: 500;
    padding-right: 0;
    font-size: 0.725rem;
    /* line-height: 1.875rem; */
    letter-spacing: -0.00938rem;
    color: #505050;
}

.task-remark-button{
    font-size: 0.7875rem;
    font-weight: 500;
    letter-spacing: 0.02406rem;
}

.task-remark-modal{
    background: #FFF;
    padding: 1rem 2.1875rem 0rem 2.1875rem;
    gap: 1.25rem;
    justify-content: center;
}

.task-remark-area{
    border-radius: 1.25rem;
    text-decoration: none;
    resize: none;
    background: #EFEFEF;
    border: none;
}

@media only screen and (min-width: 768px){
    .task-status-dropdown{
        width: 8.5rem !important;
        height: 2.25rem;
    }
    .task-remark-button{
        width: 6.875rem;
        height: 2.25rem;
    }
    .agent-task-description{
        width: 10.25rem;
        height: 2.25rem;
    }
    .task-remark-modal{
        border-radius: 1.5rem;
    }
    .task-remark-area{
        height: 16.875rem;
    }
}

@media only screen and (max-width: 768px){
    .task-status-dropdown{
        width: 10rem !important;
        height: 2rem;
    }
    .task-remark-button{
        width: 10rem;
        height: 2rem;
    }
    .agent-task-description{
        width: 11.25rem;
        height: 2rem;
    }
    .task-remark-modal{
        border-radius: 2rem;
    }
}

.task-status-dropdown.form-select{
    background-position: right 0.5rem center !important;
}

.task-status-dropdown .menu{
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.775rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025em;
    width: 10.8125rem;
    height: 10rem;
    padding: 0rem 0rem 5.625rem 0rem;
    border-radius: 8px !important;
    
}

.react-confirm-alert{
    z-index: 3 !important;
}