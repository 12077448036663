.sidebar-items {

    list-style-type: none;

}

.main-content {
    transition: margin-left 0.3s ease-out;
    margin-left: 0rem;
}

.main.sidebar-open .main-content.agent{
    margin-left: 10rem;
}
.main.sidebar-open .main-content.company{
    margin-left: 20rem;
}

.link-container{
    text-align: left;
}

@media only screen and (max-width: 768px) {
    .main.sidebar-open .main-content.agent{
        margin-left: 0rem;
    }
    .main.sidebar-open .main-content.company{
        margin-left: 0rem;
    }
  }