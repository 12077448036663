.agent-cal {
    font-size: small;
    width: fit-content;
}

#agent-header-btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.agent-card {
    border-radius: 2rem;
    background-color: #ffffff;
    box-shadow: 1px 1px 8px #818181;
    transition: all .1s;
    width: 100%;
}

.agent-card.not_active{
    background-color: #e1dfdf;
}


.agent-card-img img{
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 1.5rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    background: #dfdfdf;
}

.agent-card-btn {
    border-radius: 2rem;
}

.agent-card-btn-sm {
    font-size: 10px;
    font-weight: 500;
    border-radius: 1rem;
    height: 2.25rem !important;
}

.agent-card-btn-xs {
    font-size: 10px;
    font-weight: 500;
    border-radius: 1rem;
    width: 2.25rem !important;
    aspect-ratio: 1/1 !important;
    display: flex;
    padding-top: 0.5rem;
    /* justify-content: center; */
    /* align-items: center; */
}

.agent-card-data {
    color: #3E66CD;
    font-weight: 500;
    font-size: 23px;
}

.agent-card-label {
    color: #8A8A8A;
    font-weight: 400;
    font-size: 12px;
}

.agent-card-name {
    color: #555555;
    font-weight: 600;
    font-size: 16px;
}

.agent-card-role {
    color: #555555;
    font-weight: 400;
    font-size: 12px;
}

.agent-modal {
    border-radius: 2rem;
}

.asign-lead-close {
    position: absolute;
    top: 0;
    right: 0;
}

.assign-table-container {
    background-color: #F8F8F8;
    border-radius: .8rem;

}

.assign-table-header {
    font-weight: 600;
    font-size: 13px;
    color: #878787;
}

.assign-table-body {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

}

.assign-table-body::-webkit-scrollbar {
    display: none;
}

.assign-table-content {
    background-color: #ffffff;
    border-radius: .8rem;
    font-size: 13px;
    font-weight: 500;
}


.scale-2{
    /* position: relative; */
    width: 200%;
    height: 200%;
    z-index: 9999!important;
    transition: all 2s;
}

.agent-info-btn{
    padding: 0rem 0.6rem 0rem 0.6rem !important;
    align-items: center;
}
.agent-info-text{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.agent-list-popover{
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 1rem;
    width: 16.9rem;
    overflow-y:hidden;
    overflow-x: hidden;
}

.agent-popover-card{
    width: 15.75rem;
    background: #B9E6EF;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    color: #2C2C2C;
    font-size: 0.7425rem;
    line-height: 1rem;
}

.agent-popover-card .datas{
    height: 3.5rem;
}

.agent-popover-container{
    height: 15.3rem;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

}

.agent-popover-container::-webkit-scrollbar {
    display: none;
}

.agent-hover-card {
    position: absolute;
    border-radius: 2rem;
    background-color: #ffffff;
    box-shadow: 1px 1px 8px #818181;
    /* transition: all .1s; */
    transition: width .1s,max-height 1s,visibility 1s;
    /* overflow: hidden; */
    max-height: 12rem;
    width: 23.76666667%;
}

.agent-hover-card.hide {
    z-index: -1;
    visibility: hidden;
}

.agent-hover-card.show:hover{
    z-index: 10;
    visibility: visible;
    max-height: 800px;
    width: 24.5%;

}

.agent-hover-data {
    color: #3E66CD;
    font-weight: 500;
    font-size: 14px;
}

.agent-hover-label {
    color: #8A8A8A;
    font-weight: 400;
    font-size: 12px;
}
.agent-hover-name {
    color: #555555;
    font-weight: 600;
    font-size: 12px;
}

.agent-hover-role {
    color: #555555;
    font-weight: 400;
    font-size: 10px;
}

.toggle.off,.on {
  background-color: lightgray;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  height: 15px;
  position: relative;
  width: 25px !important;
  /* -webkit-appearance: none;
  -moz-appearance: none; */
}

button:hover { background-color: lightgray; }
button:focus,
button:active { outline: none; }
button::-moz-focus-inner { border: 0; }

.toggle>.pin {
  background-color: white;
  border-radius: 15px;
  height: 12.6px;
  left: 1px;
  position: absolute;
  top: 1.35px;
  width: 13px;
  transition: left ease .3s;
}

.toggle.on {
  background-color: green;
}

.toggle.off{
    background-color: red !important;
}

button.on .pin {
  left: 10.85px;
}

.swal2-confirm,.swal2-deny{
    justify-content: space-around;
    align-items: center;
    /* background-color: blue !important; */
    outline: none !important;
    border-radius:15px !important;
    /* margin-right: 30px; */
}