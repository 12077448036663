.register-banner{
    overflow: hidden;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 20rem 0 20rem 0;
}
.register-banner img{
    transform: scale(1.2);
    margin-top: -30%;
    margin-left: 10%;
    animation-name: mybanner;
    animation-duration: 1s;
}
@keyframes mybanner {
    from {
        margin-top: 0%;
    margin-left: 0%;
    }
    to {
        margin-top: -30%;
    margin-left: 10%;
    }
  }

.register-user-img{
    display: flex;
    justify-content: center;
    width: 9rem;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border:solid 2px #133896;
    background: #EAEAEA;
}
.register-text{
    border: none !important;
    box-shadow: 0 0 .4rem .1rem #d4d1d1;
    border-radius: 1rem;
    padding: .8rem;
}
.register-signin{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.registerUser{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.register-user-img img{

    /* width: 9rem;
    height: 9rem; */
    border-radius: 50%;
    object-fit: cover;
    background: #dfdfdf;
}