#addLead {
    font-size: small;
    width: fit-content;
}

#leadupload {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.dashboard-card {
    border-radius: 2rem;
    box-shadow: 1px 1px 8px #818181;
    transition: all .1s;
    /* height: 7.2rem; */
}

.dashboard-card.agent {
    background-color: #1E4ECA;

}

.dashboard-card.company {
    background-color: #ffffff !important;
}

.dashboard-card:hover {
    transform: scale(1.05);
}

.dashboard-card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    width: 4.325rem;
    aspect-ratio: 1 / 1;
}

.incoming-call-card {
    /* background-color:pink; */
    height: 40px;
    /* width: 40px; */
    float: left;
}

.outgoing-call-card {
    /* background-color:brown; */
    height: 40px;
    /* width: 40px; */
    float: right;
}

.call-seperator {
    height: 80%;
    width: 1px;
    background: #B6B6B6;
    top: 10%;
    bottom: 0;
    position: absolute;
    left: 50%;
}

.dashboard-call-count {
    font-weight: 900;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #242424;
    letter-spacing: -0.045em;
}

.dashboard-call-count.agent {
    color: #ffffff;
}

.dashboard-call-container {
    position: relative;
}

.call-clearfix {
    clear: both;
}

.dashboard-img-content {
    width: 55%;
}

.dashboard-card-img.company {
    background-color: #E4ECFF;

}

.dashboard-card-img.agent {
    background-color: #7895df;

}

.dashboard-card-title.company {
    color: #636363;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.015em;
}

.dashboard-card-title.agent {
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}

.dashboard-card-count1.company {
    color: #242424;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.015em;

}

.dashboard-card-count2 {
    font-weight: 600;
    font-size: 14px;
    /* line-height: 14px; */
    color: #3E66CD;
    letter-spacing: -0.015em;
}

.dashboard-card-count2.agent {
    color: #ffffff;
}

.dashboard-card-sub1 {
    font-weight: 600;
    font-size: 11px;
    /* line-height: 14px; */
    color: #686868;
    letter-spacing: -0.015em;
}

.dashboard-card-sub1.agent {
    color: #CFCFCF;
}

.dashboard-card-sub2 {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #686868;
    letter-spacing: -0.015em;
}

.dashboard-card-sub2.agent {
    color: #CFCFCF;
}

.dashboard-card-count1.agent {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.015em;
}

.chart-card {
    border-radius: 2rem;
    background-color: #ffffff;
    /* box-shadow: 1px 1px 8px #818181; */
    transition: all .1s;
}

.source-chart {
    border-radius: 2rem;
    background-color: #ffffff;
    /* box-shadow: 1px 1px 8px #818181; */
    transition: all .1s;
    /* height: 25rem !important; */
}

.upload-modal {
    border-radius: 2rem;
}

.lead-image-upload {
    border: .2rem dashed #C1C1C1;

}

.browse-btn {
    border-radius: 2rem;
}

.uploadPreview {
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.dashboard-chart-sub {
    font-size: 10px;
    color: #929292;
    font-weight: 600;
}

.pie-chart-text-container {
    border-collapse: separate;
    border-spacing: 10px;
}

.chart-badge1 {
    background-color: #7B06C2 !important;
}

.chart-badge2 {
    background-color: #0037C3 !important;
}

.chart-badge3 {
    background-color: #CA380A !important;
}

.chart-badge4 {
    background-color: #BC007C !important;
}

.chart-badge5 {
    background-color: #FFC702 !important;
}

.chart-badge-text {
    font-size: 10px;
    font-weight: 400;
}

.lead-template a {
    text-decoration: none;
    color: #ffffff;
}

.mainDiv {

    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    /* padding-bottom: 0; */
    height: 24%;
    width: 100%;
    background-color: #ece9e9;
    border-radius: 10px;
    margin: 1rem;
    position: relative;
}

/* .mainDivSmall{
    padding-left: 0;
    height: 1.5rem;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 12px;
    margin: 1rem;
 } */

.childDiv {
    /* max-width: 100%; */
    padding: 0 0 0 0;
    margin: 0;
    position: relative;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
}

.mainDiv>.text {
    z-index: 2;
    height: 1.0rem;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}

.bar-title {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 13px;
}

.chart-card.source-chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    height: 100%;
}

.bar-cont {
    margin-bottom: 10px;
}

.mainDiv.source-card {
    /* background-color:red; */
    height: 60%;
    /* margin-bottom:; */
    /* padding-bottom: 0; */
    position: relative;
}


@media screen and (width>=1270px) {
    .mainDiv {
        margin: 0.6rem;
    }

    .bar-title {
        font-size: 11px;
    }

    .mainDiv>.text {
        font-size: 10px;
    }

    .bar-cont {
        margin-bottom: 5px;
    }
}

@media screen and (width>=1400px) {
    .mainDiv {
        height: 18px;
        margin: 0.9rem;
    }

    .bar-title {
        font-size: 12px;
    }

    .mainDiv>.text {
        font-size: 11px;
    }

    .bar-cont {
        margin-bottom: 5px;
    }
}

@media screen and (width>=1500px) {
    .mainDiv {
        height: 18px;
        margin: 0.7rem;
    }

    .bar-title {
        font-size: 15px;
    }

    .mainDiv>.text {
        font-size: 15px;
    }

    .bar-cont {
        margin-bottom: 10px;
    }
}

@media screen and (width>=1610px) {
    .mainDiv {
        height: 18px;
        margin: 0.8rem;
    }

    .bar-title {
        font-size: 15px;
    }

    .mainDiv>.text {
        font-size: 15px;
    }

    .bar-cont {
        margin-bottom: 17px;
    }
}

@media screen and (width>=1780px) {
    .mainDiv {
        height: 21px;
        margin: 1rem;
    }

    .bar-title {
        font-size: 18px;
    }

    .mainDiv>.text {
        font-size: 18px;
    }

    .bar-cont {
        margin-bottom: 18px;
    }
}

@media screen and (width>=2100px) {
    .bar-title {
        font-size: 18px;
        margin: 0.8rem;

    }

    .mainDiv>.text {
        font-size: 18px;
    }

    .bar-cont {
        margin-bottom: 18px;
    }
}





.notification-pop-container {
    position: fixed;
    right: -300px;
    /* Initial position outside the screen */
    border-radius: .8rem;
    transform: translate(0, -50%);
    opacity: 0;
    transition: right 0.5s ease-in-out, transform 5s;
    padding-right: 0rem;
    height: 14rem;
    width: 21.375rem;
}

@media only screen and (min-width: 768px) {
    .notification-pop-container {
        margin-bottom: -7rem;
        bottom: 0;
    }
}

@media only screen and (max-width: 768px) {
    .notification-pop-container {
        margin-top: 15rem;
        top: 0;
    }
}

.popup-note {
    overflow-wrap: break-word;
    overflow: scroll;
    height: 2rem;
    width: 16rem;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-size: 10px;
    font-weight: 400;
    color: #505050;
}

.popup-note::-webkit-scrollbar {
    display: none;
}

.popup-data {
    overflow: visible;
    color: #242424;
    font-size: 11px;
    font-weight: 700;
    padding: 0rem .0rem .1rem .0rem;
}

.popup-card {
    border-radius: 1.25rem;
    height: 4.563rem !important;
    background-color: #EEEEEE;
    display: flex !important;
    opacity: 0;
    width: 100%;
    margin-bottom: .5rem;
    ;
    padding: .3rem .5rem .3rem 1rem;
    box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.25);
}

.popup-name {
    width: 3.125rem;
    aspect-ratio: 1/1;
    border-radius: 5rem;
    background: #DF7D53;
    font-weight: 600;
    font-size: 1.8rem;
}

.popup-logo {
    padding: .3rem 0rem .3rem .0rem !important;
}

.popup {
    position: relative;
    right: -300px;
    transform: translate(0, -50%);
    background-color: #ffffff;
    opacity: 0;
    transition: right 0.6s ease-in-out, transform 0.5s;
}

.popup.open {
    right: 1px;
    /* Slide in to visible position */
    opacity: 0;
    transform: translate(0, -50%);
    animation: slideAndFadeOut 7s ease-out backwards, transform 1s;
}

.notification-pop-container.open {
    right: 10px;
    /* Slide in to visible position */
    opacity: 0;
    transform: translate(0, -50%);
    animation: fadeOut 12s none;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translate(0, -50%);
    }

    98% {
        opacity: 1;
        transform: translate(0, -50%);
    }

    100% {
        opacity: 0;
        transform: translate(0, -100%);
    }
}

@keyframes slideAndFadeOut {
    0% {
        opacity: 0;
        transform: translateX(-100%) translate(0, -50%);
    }

    18% {
        opacity: 1;
        transform: translateX(10px) translate(0, -50%);
    }

    30% {
        opacity: 1;
        transform: translateX(0) translate(0, -50%);
    }

    90% {
        opacity: 1;
        transform: translate(0, -50%);
    }

    100% {
        opacity: 0;
        transform: translate(0, -100%);
    }
}

.dashboard-filter-view {
    width: 7.5rem;
    height: 2.1875rem;
    background: #3E66CD;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    letter-spacing: -0.01125rem;
}

.dashboard-filter-option {
    width: 5.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.1875rem;
    background: #FFF;
    border-radius: 7px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    color: #000;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: -0.01125rem;
    margin-right: 0.63rem;
}

.dashboard-filter-arrow {
    margin-right: 0.75rem;
    padding: 0.25rem 0.15rem 0.25rem 0.15rem !important;
    width: 1.875rem;
    height: 2.1875rem;
    border-radius: 7px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.filter-arrow {
    width: 1.675rem;
    height: 1.325rem;
    transition: transform 0.5s ease;
    /* transform: rotate(90deg); */
}

.rotated {
    transform: rotate(-180deg);
}

.dashboard-filter-container {
    opacity: 0;
    transition: transform 1s, z-index 0s, visibility 1.9s, opacity 0.5s ease-out;
    transform: translate(500px, 0);
    z-index: -1;
    visibility: hidden;
}

.dashboard-filter-container.show {
    opacity: 1;
    transform: translate(0, 0);
    z-index: 0;
    visibility: visible;
    transition: transform 1s, z-index 5.8s, visibility 1s, opacity 1s ease-in;

}