/* .notification-name {    
    width: 4rem;
    height: 4rem;
    object-fit:cover;
    border-radius: 5rem;
    background: #afafaf;
}

.notification-card {
    height: 5rem !important;
    aspect-ratio: 1/1;
} */

.outline-card{
    -ms-overflow-style: none;
    background-color: #fff;
    border-color: #c5c5c5;
    border-radius: .8rem;
    height: 25rem;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 21.375rem;
}
.outline-card::-webkit-scrollbar{
    display: none;
}
.notification-note{
    overflow-wrap:break-word;
    overflow: scroll;
    height: 3.3rem;
    width: 16rem;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 .3rem .1rem .7rem;
    font-size: 10px;
    font-weight: 400;
    color: #505050;
}

.notification-note::-webkit-scrollbar{
    display: none;
}

.notification-data{
    overflow: visible;
    color: #242424;
    font-size: 11px;
    font-weight: 700;
    overflow: visible;
}

.notification-card {
    background-color: #eee;
    border-radius: .8rem;
    display: flex !important;
    height: 6.5rem !important;
    margin-bottom: .5rem;
    padding: .6rem .5rem .6rem 1rem;
    width: 100%;
}

.notification-name {    
    background: #53c6df;
    border-radius: 5rem;
    font-size: 1.8rem;
    font-weight: 600;
    height: 4rem;
    width: 4rem;
}

.notification-logo {
    padding: .6rem 1rem .6rem .1rem !important;
}

.null-card{
    background-color: rgb(168, 227, 255);
    border-radius: .8rem;
    display: flex !important;
    /* height: 2.5rem !important; */
    margin-bottom: .5rem;
    padding: .6rem .5rem .6rem 1rem;
    width: 100%;
}