.profile-card-container {
    border-radius: 2rem;
    background: #ffffff;
    box-shadow: 1px 1px 8px #d6d5d5;
    transition: all .1s;
}

.profile-card {
    border-radius: 2rem;
    border: 1px solid #DADADA;
}

.profile-user-img {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* aspect-ratio: 1/1; */
    
    width: 7.5rem;
    height: 7.5rem;
    object-fit:cover;
    border-radius: 5rem;
    background: #afafaf;
}

.profile-card-title {
    font-weight: 600;
    font-size: 15px;
}

.profile-card-post {
    font-weight: 500;
    font-size: 13px;
}

.profile-card-location {
    font-weight: 400;
    font-size: 12px;

}
.profile-card-label{
    font-weight: 400;
    font-size: 12px;
}